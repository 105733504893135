<template>
  <div class="list">
    <div v-for="(m, i) in members" :key="i" class="item" @click="$emit('click-member', m)"
      :class="{
        'clickable': clickable,
        'responsive': responsive,
        'loading': m.loadingStatus != 'done',
        'rank-officer': m.rank == 1,
        'rank-senior': m.rank == 2,
        'rank-middle': m.rank == 3,
        'rank-junior': m.rank == 4,
        'rank-alt': m.rank == 5,
        'rank-novice': m.rank == 6,
      }">
      <img class="image avatar" :src="image(m, 'avatar')" alt="">
      <img class="image inset" :src="image(m, 'inset')" alt="">
      <img v-if="specs && m.spec" class="spec" :src="specs.find(e => e.id == m.spec).media" alt="">
      <div class="name">{{ m.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classes: { type: Array, required: true },
    specs: { type: Array },
    members: { type: Array, required: true },
    clickable: { type: Boolean },
    responsive: { type: Boolean }
  },
  methods: {
    image: function (member, prop) {
      return member[prop] || this.classes.find(e => e.id == member.class).media
    },
  }
}
</script>

<style scoped>

.list {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.item {
  position: relative;
  margin: 10px;
  display: flex;
  font-size: 16px;
}

.item .image {
  border: 4px solid transparent;
  border-bottom: 0;
  object-fit: cover;
  transition: filter .25s ease-out;
}

.item.clickable:hover .image {
  filter: brightness(1.6);
}

.item.clickable .image {
  cursor: pointer;
}

.item.loading .image {
  filter: grayscale();
}

.item .image.avatar {
  width: 92px; /* 84 + 2 x border */
  height: 88px; /* 84 + 1 x border */
}

.item .image.inset {
  display: none;
  width: 238px; /* 230 + 2 x border */
  height: 120px; /* 116 + 1 x border */
}

.item.rank-officer .image { border-color: mediumpurple; }
.item.rank-senior .image { border-color: deepskyblue; }
.item.rank-middle .image { border-color: lightskyblue; }
.item.rank-junior .image { border-color: powderblue; }
.item.rank-alt .image { border-color: lightgray; }
/* .item.rank-novice .image {} */

.item .spec {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0,0,0,1);
  pointer-events: none;
}

.item .name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: .15em;
  text-align: center;
  font: var(--font-text-bold-extra);
  font-size: 75%;
  color: white;
  text-shadow: 0 0 2px black;
  background-image: linear-gradient(0deg, rgba(0,0,0,.8) 25%, transparent 100%);
  pointer-events: none;
}

@media (min-width: 840px) {
  .item.responsive .image.avatar { display: none; }
  .item.responsive .image.inset { display: block; }

  .item.responsive .spec {
    width: 56px;
    height: 56px;
  }

  .item.responsive .name {
    font-size: 115%;
  }
}

</style>
