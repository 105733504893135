var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},_vm._l((_vm.members),function(m,i){return _c('div',{key:i,staticClass:"item",class:{
      'clickable': _vm.clickable,
      'responsive': _vm.responsive,
      'loading': m.loadingStatus != 'done',
      'rank-officer': m.rank == 1,
      'rank-senior': m.rank == 2,
      'rank-middle': m.rank == 3,
      'rank-junior': m.rank == 4,
      'rank-alt': m.rank == 5,
      'rank-novice': m.rank == 6,
    },on:{"click":function($event){return _vm.$emit('click-member', m)}}},[_c('img',{staticClass:"image avatar",attrs:{"src":_vm.image(m, 'avatar'),"alt":""}}),_c('img',{staticClass:"image inset",attrs:{"src":_vm.image(m, 'inset'),"alt":""}}),(_vm.specs && m.spec)?_c('img',{staticClass:"spec",attrs:{"src":_vm.specs.find(function (e) { return e.id == m.spec; }).media,"alt":""}}):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(m.name))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }