<template>
<div>
  <v-tabs centered background-color="transparent" class="menu-bar mt-4 mb-10">
    <v-tab to="/roster" exact>
      <v-icon>mdi-glass-mug-variant</v-icon>
      <span class="d-none d-sm-block pl-2">Ростер</span>
    </v-tab>
    <v-tab to="/roster/rankings">
      <v-icon>mdi-podium</v-icon>
      <span class="d-none d-sm-block pl-2">Лідери</span>
    </v-tab>
    <v-tab to="/roster/recipes">
      <v-icon>mdi-hand-saw</v-icon>
      <span class="d-none d-sm-block pl-2">Ремесло</span>
    </v-tab>
    <v-tabs-slider></v-tabs-slider>
  </v-tabs>
  <div v-if="loading" class="text-center">
    Завантаження...
    <div v-if="loadingTakesTooLong" class="py-5">
      Виглядає, що сервер втомився й приліг відпочити <v-icon>mdi-sleep</v-icon><br>
      Зазвичай пробудження займає не довше хвилини.
    </div>
  </div>
  <div v-if="failure" class="text-center">
    Помилка завантаження <v-icon>mdi-emoticon-sad-outline</v-icon><br>
    Повторіть спробу пізніше.
  </div>
  <div v-if="backgroundLoading" class="floating-loading-indicator">
    Завантаження...
  </div>
</div>
</template>

<script>

export default {
  props: {
    loading: { type: Boolean },
    failure: { type: Boolean },
    backgroundLoading: { type: Boolean }
  },
  data() {
    return {
      loadingTakesTooLong: false,
      loadingTakesTooLongTimeoutId: 0
    }
  },
  created: function() {
    this.loadingStartedAt = Date.now()
    this.loadingTakesTooLongTimeoutId = setTimeout(() => {
      this.loadingTakesTooLong = this.loading
    }, 5000)
  },
  destroyed: function() {
    clearTimeout(this.loadingTakesTooLongTimeoutId)
  },
  watch: {
    loading: function (next, prev) {
      if (next === false && prev === true) {
        // report time in seconds; no need for milliseconds
        let time = Date.now() - this.loadingStartedAt
        time = Math.round(time/1000)
        this.$gtag.event('roster_loading_time', { time })
      }
    }
  }
}

</script>

<style scoped>

.v-tabs .v-tab {
  font: var(--font-text-bold);
  font-size: 14px;
}

.v-tabs .v-tab--active,
.v-tabs .v-tabs-slider {
  color: var(--color-link);
}

.floating-loading-indicator {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: .25em .75em;
  font-size: 85%;
  background-color: wheat;
  box-shadow: 0 0 8px rgba(0,0,0,.5);
  color: black;
  z-index: 1;
}

</style>
